export default function BotFloatBanner() {
  return (
    <>
      {/*
        Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
        your content from being obscured when the user scrolls to the bottom of the page.
      */}
      <div className="pointer-events-none fixed inset-x-0 top-[90px] md:top-[140px] sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8 z-0">
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-black px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
          <p className="flex items-center flex-wrap text-sm leading-6 text-white">

            <strong className="font-semibold">Important Notice: </strong>
            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
              <circle cx={1} cy={1} r={1} />
            </svg>
            <span className="flex items-center">
              You can swap a maximum of 10,000,000 DogeDash per week
            </span>

          </p>
        </div>
      </div>
    </>
  )
}
