import { Fragment, useCallback, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useAuth from "../../hooks/useAuth";

import mm from '../../assets/img/metamask.png'
import wc from '../../assets/img/walletconnect.svg'

export default function WalletConnect( props ) {
  const [open, setOpen] = useState(false)
  const {login} = useAuth()

  const {
      setStep,
  } = props;

    const handleConnect = useCallback(
        ( connector ) => {
            try {
                login( connector );
                setStep(2);
            }
            catch ( err ) {
                console.warn( 'Error connecting:', err.message );
            }
        }, []
    );

  return (
        <>
            <div className="flex justify-center mt-8">
                <button
                    onClick={() => setOpen(true)}
                    type="submit"
                    className="inline-flex items-center md:items-end px-5 py-4 border border-black text-xs font-black rounded-full shadow-sm text-black bg-primary ml-2 hover:text-primary hover:bg-black hover:border-primary">
                        CONNECT WALLET
                    </button>
            </div>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative inline-block align-bottom bg-primary rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-sm max-w-lg w-full" style={{maxWidth: '558px'}}>
                                <div className='flex py-10'>
                                    <h4 className='font-black text-black text-4xl upeprcase pl-4 border-l-4 border-white mx-auto'>CONNECT WALLET</h4>
                                </div>

                                <div className='bg-white w-full pt-6 pb-12 px-6 flex flex-col justify-center'>
                                    <div className='mx-auto space-y-4' style={{maxWidth: '334px'}}>
                                        <button className='flex items-center p-2 hover:bg-primary rounded-xl w-full' onClick={() => {handleConnect('injected')}}>
                                            <figure className='w-24 h-24 border border-lightgray rounded-xl'>
                                                <img src={mm} width={107} height={107} />
                                            </figure>
                                            <h5 className='text-2xl font-black text-black uppercase ml-4'>METAMASK</h5>
                                        </button>


                                        <button className='flex items-center p-2 hover:bg-primary rounded-xl w-full' onClick={() => {handleConnect('walletconnect')}}>
                                            <figure className='w-24 h-24 border border-lightgray rounded-xl'>
                                                <img src={wc} width={107} height={107} />
                                            </figure>
                                            <h5 className='text-2xl font-black text-black uppercase ml-4'>WALLETCONNECT</h5>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
  )
}