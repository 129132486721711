import React, { useState } from "react";
import Helmet from "react-helmet";
import WalletConnect from "../components/modals/WalletConnect";

import Step1 from "../components/steps/Step1";
import Step2 from "../components/steps/Step2";
import Step3 from "../components/steps/Step3";
import Step4 from "../components/steps/Step4";

import '../style/Swap.css';
import { ddAmountToH1 } from "../utils/swap";

const Swap = () => {
    const [step, setStep] = useState(1)
    const [dogedashAmount, setDogedashAmount] = useState(0)

    const title = "Migrate your DogeDash to HELLO now | HELLO.ONE";
    const description = "The migration from Doge Dash to HELLO is now available. Swap your tokens now and become a holder of a new future in crypto space! HELLO.ONE";

    // hello contract:      0xaB152c0De7D977FB43DDa704aF14eDE8bdec692D
    // dogedash contract:   0xC43Aa1B356688258B1CD977e76551f392174Fd4f
    // swap contract:       0x8424314768303fb07bFA809AEdf17421059a6569

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />

                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content="https://hello.one/" />
                <meta property="og:image" content="https://via.placeholder.com/1080x600" />

                <meta property="twitter:card" content="summary" />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image" content="https://via.placeholder.com/1080x600" />
                <meta property="twitter:url" content="htts://hello.one/" />
            </Helmet>


            <section className="bg-primary py-12 md:py-22 mt-40 md:mt-48">
                <div className="max-w-screen-sm px-4 md:px-8 mx-auto" style={{ maxWidth: '1082px' }}>
                    <div className="relative bg-black w-full p-4 md:p-8 rounded-2xl text-white z-10">
                        <div className="flex flex-col my-10 items-center space-y-4">
                            <h1 className="text-2xl md:text-4xl pl-4 font-black border-l-4 border-l-primary mx-auto">
                                {step === 2 ? (
                                    "ENTER AMOUNT"
                                ) : step === 3 ? (
                                    "MIGRATE TOKENS"
                                ) : step === 4 ? (
                                    "SUCCESS, YOU DID IT!"
                                ) : (
                                    "CONNECT WALLET"
                                )}
                            </h1>

                            {step <= 1 ? (
                                <p>
                                    Follow this simple 4-step process to migrate your Doge Dash into HELLO tokens
                                </p>
                            ) : ""}
                        </div>

                        <div className="text-center space-y-4 mt-8 mx-auto">
                            <ul className="flex text-xs justify-center mw-step mx-auto">

                                <li>
                                    <button className={`stepper ${step <= 1 ? 'active' : ''}`}>
                                        <span className="small font-black text-xl" style={{ width: '50px', height: '50px' }}>
                                            1
                                        </span>
                                        <span className="big">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="224.804" height="59.65">
                                                <g id="Group_1056" data-name="Group 1056" transform="translate(10 3)">
                                                    <g id="Group_1053" data-name="Group 1053" clipPath="url(#clipPath)">
                                                        <path id="Path_969" data-name="Path 969" d="M179.074,53.65H3.976a3.637,3.637,0,0,1-2.641-6.138L18.39,29.5a3.638,3.638,0,0,0,0-5L1,6.138A3.637,3.637,0,0,1,3.645,0H179.061A3.639,3.639,0,0,1,181.7,1.136l22.106,23.347a3.635,3.635,0,0,1-.016,5.018L181.7,52.531a3.638,3.638,0,0,1-2.625,1.119" fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="2" />
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="overlay font-black">
                                                1: CONNECT WALLET
                                            </span>
                                        </span>
                                    </button>
                                </li>

                                <li>
                                    <button className={`stepper ${step === 2 ? 'active' : ''}`}>
                                        <span className="small font-black text-xl">
                                            2
                                        </span>
                                        <span className="big">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="224.804" height="59.65">
                                                <g id="Group_1056" data-name="Group 1056" transform="translate(10 3)">
                                                    <g id="Group_1053" data-name="Group 1053" clipPath="url(#clipPath)">
                                                        <path id="Path_969" data-name="Path 969" d="M179.074,53.65H3.976a3.637,3.637,0,0,1-2.641-6.138L18.39,29.5a3.638,3.638,0,0,0,0-5L1,6.138A3.637,3.637,0,0,1,3.645,0H179.061A3.639,3.639,0,0,1,181.7,1.136l22.106,23.347a3.635,3.635,0,0,1-.016,5.018L181.7,52.531a3.638,3.638,0,0,1-2.625,1.119" fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="2" />
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="overlay font-black">
                                                2: ENTER AMOUNT
                                            </span>
                                        </span>
                                    </button>
                                </li>

                                <li>
                                    <button className={`stepper ${step === 3 ? 'active' : ''}`}>
                                        <span className="small font-black text-xl">
                                            3
                                        </span>
                                        <span className="big">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="224.804" height="59.65">
                                                <g id="Group_1056" data-name="Group 1056" transform="translate(10 3)">
                                                    <g id="Group_1053" data-name="Group 1053" clipPath="url(#clipPath)">
                                                        <path id="Path_969" data-name="Path 969" d="M179.074,53.65H3.976a3.637,3.637,0,0,1-2.641-6.138L18.39,29.5a3.638,3.638,0,0,0,0-5L1,6.138A3.637,3.637,0,0,1,3.645,0H179.061A3.639,3.639,0,0,1,181.7,1.136l22.106,23.347a3.635,3.635,0,0,1-.016,5.018L181.7,52.531a3.638,3.638,0,0,1-2.625,1.119" fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="2" />
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="overlay font-black">
                                                3: MIGRATE TOKENS
                                            </span>
                                        </span>
                                    </button>
                                </li>

                                <li>
                                    <button className={`stepper ${step === 4 ? 'active' : ''}`}>
                                        <span className="small font-black text-xl">
                                            4
                                        </span>
                                        <span className="big">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="224.804" height="59.65">
                                                <g id="Group_1056" data-name="Group 1056" transform="translate(10 3)">
                                                    <g id="Group_1053" data-name="Group 1053" clipPath="url(#clipPath)">
                                                        <path id="Path_969" data-name="Path 969" d="M179.074,53.65H3.976a3.637,3.637,0,0,1-2.641-6.138L18.39,29.5a3.638,3.638,0,0,0,0-5L1,6.138A3.637,3.637,0,0,1,3.645,0H179.061A3.639,3.639,0,0,1,181.7,1.136l22.106,23.347a3.635,3.635,0,0,1-.016,5.018L181.7,52.531a3.638,3.638,0,0,1-2.625,1.119" fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="2" />
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="overlay font-black">
                                                4: COMPLETE
                                            </span>
                                        </span>
                                    </button>
                                </li>

                            </ul>
                        </div>

                        {step === 2 ? (
                            <Step2
                                setStep={setStep}
                                dogedashAmount={dogedashAmount}
                                setDogedashAmount={setDogedashAmount}
                            />
                        ) : step === 3 ? (
                            <Step3
                                setStep={setStep}
                                dogedashAmount={dogedashAmount}
                            />
                        ) : step === 4 ? (
                            <Step4
                                convertedAmount={ddAmountToH1(dogedashAmount)}
                            />
                        ) : (
                            <>
                                <Step1 />
                                <WalletConnect setStep={setStep} />
                            </>
                        )}

                        {/* <div className="mx-auto mt-8" style={{maxWidth: '562px'}}>
                            <p className="text-center">
                                Enter your email below to stay in the loop on all future announcements and updates. 
                            </p>
                            <form action="#" className="mt-8 sm:mx-auto sm:max-w-lg sm:flex">
                                <div className="min-w-0 flex-1">
                                    <label for="cta-email" className="sr-only">Add your email address</label>
                                    <input id="cta-email" type="email" className="block w-full border border-transparent rounded-full px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-1 focus:ring-primary" placeholder="Add your email address" />
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-3">
                                    <button type="submit" className="inline-flex items-center md:items-end px-5 py-4 border border-black text-xs font-black rounded-full shadow-sm text-black bg-primary ml-2 hover:text-primary hover:bg-black hover:border-primary">STAY IN THE LOOP</button>
                                </div>
                            </form>
                        </div> */}

                        <div className="flex flex-col justify-between mt-8 max-w-[270px]">
                            <div className="font-black">
                                NEED HELP?
                            </div>

                            <div className="grid grid-cols-2 mt-1 space-x-1 text-center divide-x-2 -ml-1 divide-solid divide-primary text-primary">
                                <a target={'_blank'} href={`https://cdn.hello.one/Migration+Guide.pdf`} className="block hover:font-black focus:font-black text-sm">
                                    DOWNLOAD GUIDE
                                </a>
                                <a target={'_blank'} href={`https://www.youtube.com/watch?v=N6mKToCsu48`} className="block hover:font-black focus:font-black text-sm">
                                    WATCH TUTORIAL
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Swap;