import { useCallback } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import sample from 'lodash/sample'
import { InjectedConnector, NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected, } from '@web3-react/injected-connector'
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect, WalletConnectConnector, } from '@web3-react/walletconnect-connector'
import { ChainID, POLLING_INTERVAL, RPC_URL } from '../constants/provider';
import { setupNetwork } from '../utils/web3React'

const nodes = [
    RPC_URL
]

const rpcUrl = sample( nodes )

const injected = new InjectedConnector( {supportedChainIds: [ChainID]} )

const walletconnect = new WalletConnectConnector( {
    chainId: ChainID,
    rpc: {[ChainID]: rpcUrl},
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
} )

const connectorsByName = {
    "injected": injected,
    "walletconnect": walletconnect
}

const connectorLocalStorageKey = "DogeDash_HelloOne_TokenSwap_Connector";

const useAuth = () => {
    const {activate, deactivate} = useWeb3React()

    const login = useCallback(
        ( connectorID ) => {
            const connector = connectorsByName[connectorID]
            // console.log('Attempting login using connector', connector);

            if ( connector ) {
                activate( connector, async ( error ) => {
                    console.error( 'connector activation error:', error );

                    if ( error instanceof UnsupportedChainIdError ) {
                        const hasSetup = await setupNetwork()
                        if ( hasSetup ) {
                            activate( connector )
                        }
                    }
                    else {
                        window.localStorage.removeItem( connectorLocalStorageKey )
                        if ( error instanceof NoEthereumProviderError ) {
                            console.log( 'Provider Error', 'No provider was found' )
                        }
                        else if (
                            error instanceof UserRejectedRequestErrorInjected ||
                            error instanceof UserRejectedRequestErrorWalletConnect
                        ) {
                            if ( connector instanceof WalletConnectConnector ) {
                                connector.walletConnectProvider = null
                            }
                            console.error( 'Authorization Error', 'Please authorize to access your account' )
                        }
                    }
                } ).then( () => console.log( 'connector activated' ) )

                window.localStorage.setItem( connectorLocalStorageKey, connectorID )
            }
            else {
                console.error( 'Unable to find connector', 'The connector config is wrong' )
            }
        },
        [activate],
    )

    const logout = useCallback( () => {
        deactivate()
        console.log( 'connector deactivated' );

        // This localStorage key is set by @web3-react/walletconnect-connector
        if ( window.localStorage.getItem( 'walletconnect' ) ) {
            connectorsByName.walletconnect.close()
            connectorsByName.walletconnect.walletConnectProvider = null
        }
        window.localStorage.removeItem( connectorLocalStorageKey )
    }, [deactivate] )

    return {login, logout}
}

export default useAuth
