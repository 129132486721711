import React, { useCallback, useEffect, useState } from "react";
import doge from '../../assets/img/dogedash.png';
import hello from '../../assets/img/H1-asset.svg';
import ReadonlyCoinsAmount from "../ReadonlyCoinsAmount";
import useSwap from "../../hooks/useSwap";
import useApprove from "../../hooks/useApprove";
import { ddAmountToH1 } from "../../utils/swap";

const Step3 = (props) => {
    const { onApprove } = useApprove();
    const { onSwap } = useSwap();

    const [isApproved, setApproved] = useState(false);
    const [helloOneAmount, setHelloOneAmount] = useState('');
    const [loading, setLoading] = useState(false);

    const spinner = `APPROVING <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve"><path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/><path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite"/></path></svg>`;

    const {
        setStep,
        dogedashAmount = 0,
    } = props;

    useEffect(
        () => {
            const h1Amount = ddAmountToH1(dogedashAmount);
            setHelloOneAmount(h1Amount);
        },
        [dogedashAmount, setHelloOneAmount]
    );

    const handleApproveClicked = useCallback(
        async () => {
            setLoading(true);
            try {
                const approveStatus = await onApprove(dogedashAmount);
                if (!approveStatus) {
                    throw 'Failed approving';
                }

                console.log('APPROVE SUCCESS');
                setApproved(true);
            }
            catch (err) {
                console.error(err);
                setApproved(false);
            } finally {
                setLoading(false);
            }
        },
        []
    )

    const handleMigrateClicked = useCallback(
        async () => {
            setLoading(true);
            try {
                const swapStatus = await onSwap(dogedashAmount);
                if (!swapStatus) {
                    throw 'Failed swapping tokens';
                }

                console.log('SWAP SUCCESS');
                setStep(4);
            }
            catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        },
        []
    )

    return (
        <>

            <div className="text-center space-y-4 mt-8 mx-auto" style={{ maxWidth: '700px' }}>

                <h4 className="text-base mx-auto" style={{ maxWidth: '350px' }}>
                    Click “Migrate Tokens” to confirm the swap.<br />
                    <strong>
                        The migration ratio is set to 100:1
                        <br />
                        Example: 1,000,000 Doge Dash = 10,000 HELLO
                    </strong>
                </h4>

                <form>
                    <div className="mx-auto md:grid grid-cols-11">

                        <div className="flex flex-col md:col-span-5">
                            <div className="flex items-center">
                                <figure className="rounded-full border-1 border-white">
                                    <img src={doge} width={47} height={47} alt="Doge Dash" />
                                </figure>
                                <h4 className="ml-3">
                                    DogeDash
                                </h4>
                            </div>
                            <div className="mt-4">
                                <div className="min-w-0 flex-1">
                                    <ReadonlyCoinsAmount amount={dogedashAmount} />
                                </div>
                            </div>
                        </div>

                        <div className="hidden md:flex items-end justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="#00FFFF">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            </svg>
                        </div>

                        <div className="flex flex-col mt-4 md:mt-0 md:col-span-5">
                            <div className="flex items-center">
                                <figure className="rounded-full border-1 border-white">
                                    <img src={hello} width={47} height={47} alt="Doge Dash" />
                                </figure>
                                <h4 className="ml-3">
                                    Hello
                                </h4>
                            </div>
                            <div className="mt-4">
                                <div className="min-w-0 flex-1">
                                    <ReadonlyCoinsAmount amount={helloOneAmount} />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="flex justify-center mt-8">
                        {!isApproved ? (
                            <button
                                type="button"
                                className={`w-40 text-center justify-center inline-flex items-center md:items-end px-5 py-4 border border-black text-xs font-black rounded-full shadow-sm text-black bg-primary ml-2 enabled:hover:text-primary enabled:hover:bg-black enabled:hover:border-primary ${loading ? 'bg-[#B2B2B2]' : ''}`}
                                onClick={handleApproveClicked}
                                disabled={loading}
                            >
                                {loading ?
                                    `APPROVING...` : 'APPROVE'}
                            </button>
                        ) : (
                            <button
                                type="button"
                                className={`w-40 text-center justify-center inline-flex items-center md:items-end px-5 py-4 border border-black text-xs font-black rounded-full shadow-sm text-black bg-primary ml-2 enabled:hover:text-primary enabled:hover:bg-black enabled:hover:border-primary ${loading ? 'bg-[#B2B2B2]' : ''}`}
                                onClick={handleMigrateClicked}
                                disabled={loading}
                            >
                                {loading ?
                                    `
                                    MIGRATING TOKENS...` : 'MIGRATE TOKENS'}
                            </button>
                        )}
                    </div>
                </form>

            </div>

        </>
    )
}

export default Step3;