import React, { useCallback, useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { formatEther, parseEther } from "@ethersproject/units";
import doge from '../../assets/img/dogedash.png';
import { getDogedashTokenContract } from "../../utils/getContract";

const Step2 = ( props ) => {
    const {
        setStep,
        dogedashAmount,
        setDogedashAmount,
    } = props;

    const {account, library} = useWeb3React();
    const [selectedAmount, setSelectedAmount] = useState( dogedashAmount );
    const [amountInputError, setAmountInputError] = useState( '' );
    const [loadingBalance, setLoadingBalance] = useState( true );
    const [dogedashBalance, setDogedashBalance] = useState( '' );
    const amountInputRef = useRef( null );

    useEffect( () => {
        const fetchBalance = async () => {
            try {
                const dogedashTokenContract = getDogedashTokenContract( library );

                let _ddBalance = await dogedashTokenContract.balanceOf( account );
                _ddBalance = formatEther( _ddBalance.toString() );

                setDogedashBalance( _ddBalance.toString() );
                setLoadingBalance( false );
            }
            catch ( err ) {
                console.warn( 'Failed loading user DD Balance', err );
            } finally {
                setLoadingBalance( false );
            }
        }

        if ( account && library ) {
            fetchBalance().catch( err => console.warn( err ) );
        }
    }, [
        account,
        library,
    ] );

    const handleAmountChanged = useCallback(
        ( event ) => {
            const {value} = event.target;

            try {
                // remove all invalid characters
                const parsedValue = value.replace( /[^0-9.]+/g, '' );
                setSelectedAmount( parsedValue );

                // if there're more than one dot
                if ( parsedValue.match( /\./g )?.length > 1 ) {
                    return setAmountInputError( 'Invalid decimals' );
                }

                // force value to be a valid number
                const valueAsNumber = parseFloat( parsedValue );
                if ( isNaN( valueAsNumber ) || valueAsNumber <= 0 ) {
                    return setAmountInputError( 'Please fill this input with a valid amount' );
                }

                // if input value is greater then user's balance
                const valueAsEther = parseEther( parsedValue );
                const ddBalanceAsEther = parseEther( dogedashBalance );
                if ( valueAsEther.gt( ddBalanceAsEther ) ) {
                    return setAmountInputError( 'Not enough balance' );
                }
                

                return setAmountInputError( '' );
            }
            catch ( e ) {
                console.warn( e );
                setAmountInputError( 'Please fill this input with a valid amount' );
            }
        },
        [
            dogedashBalance
        ]
    );

    const handleMaxClicked = useCallback( () => {
        const input = amountInputRef.current;

        const valueSetter = Object.getOwnPropertyDescriptor( window.HTMLInputElement.prototype, "value" ).set;
        valueSetter.call( input, dogedashBalance );

        input.dispatchEvent( new Event( 'input', {bubbles: true} ) );
    }, [
        dogedashBalance,
        amountInputRef,
    ] );

    const handleConfirm = useCallback( () => {
            const amount = amountInputRef?.current?.value;
            if ( amount <= 0 ) {
                console.warn( 'Invalid DogeDash amount' );
                return;
            }

            setDogedashAmount( amount );
            setStep( 3 );
        },
        [amountInputRef]
    );

    return (
        <>

            <div className="text-center space-y-4 my-8 md:my-24 mx-auto" style={{maxWidth: '520px'}}>

                <h4 className="text-base">
                    Your Doge Dash tokens will automatically populate into the field below. Click “Confirm” to begin the migration process.
                </h4>

                <form>
                    <div className="mx-auto" style={{maxWidth: '350px'}}>
                        <div className="flex justify-between items-center ">
                            <figure className="rounded-full border-1 border-white">
                                <img src={doge} width={47} height={47} alt="Doge Dash"/>
                            </figure>
                            <h4 className="">
                                DogeDash
                            </h4>
                            <div>
                                {loadingBalance ?
                                    <span className="text-primary">Loading balance...</span> :
                                    <button
                                        type="button"
                                        className="inline-flex items-center md:items-end px-4 py-1 border-2 border-white text-xs font-black rounded-full shadow-sm text-white bg-transparent ml-2 hover:text-black hover:bg-white hover:border-transparent"
                                        onClick={handleMaxClicked}
                                    >
                                        MAX
                                    </button>
                                }
                            </div>
                        </div>

                        <div className="flex justify-center mt-8">
                            <div className="min-w-0 flex-1">
                                <label htmlFor="cta-amount" className="sr-only">How many tokens would you like to swap?</label>
                                <input
                                    id="cta-amount"
                                    ref={amountInputRef}
                                    className={`block w-full border ${amountInputError ? 'border-red-600 text-red-600' : 'border-transparent text-black focus:border-transparent focus:ring-1 focus:ring-primary'} rounded-full px-5 py-3 text-base placeholder-gray-500 shadow-sm focus:outline-none`}
                                    placeholder="1000000"
                                    value={selectedAmount}
                                    onChange={handleAmountChanged}
                                    type="text"
                                />
                            </div>
                        </div>
                        {amountInputError && (
                            <div className='text-red-200'>{amountInputError}</div>
                        )}

                        <div className="flex justify-center mt-8">
                            <button
                                type="button"
                                className="w-40 text-center justify-center items-center inline-flex items-center md:items-end px-5 py-4 border border-black text-xs font-black rounded-full shadow-sm text-black enabled:bg-primary disabled:bg-gray-400 ml-2 enabled:hover:text-primary enabled:hover:bg-black enabled:hover:border-primary"
                                onClick={handleConfirm}
                                disabled={!!amountInputError}
                            >
                                CONFIRM
                            </button>
                        </div>
                    </div>
                </form>

            </div>

        </>
    )
}

export default Step2;