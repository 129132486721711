import { useCallback } from 'react';
import { getSwapContract } from '../utils/getContract';
import { useWeb3React } from "@web3-react/core";
import { toWei } from "../utils/formatBalance";

const useSwap = () => {
    const {library} = useWeb3React();
    const swapContract = getSwapContract( library );

    const handleSwap = useCallback(
        async ( dogedashAmount ) => {
            const amountWei = toWei( dogedashAmount );
            console.log( `Trying to swap the following amount of old token: ${amountWei}` );

            const swapTx = await swapContract.swap( amountWei );
            const swapReceipt = await swapTx.wait();
            return swapReceipt.status;
        }, [swapContract]
    );

    return {onSwap: handleSwap};
};

export default useSwap;
