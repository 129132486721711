import React from "react";

const Step1 = () => {

    return(
        <>
            

            <div className="text-center space-y-4 my-8 md:my-24 mx-auto" style={{maxWidth: '520px'}}>
                
                <h4 className="text-primary text-xl">Click "Connect Wallet" below, then approve your MetaMask or WalletConnect connection in the pop-up</h4>
                
            </div>  

        </>
    )
}

export default Step1;