import React from "react";

const Step4 = (props) => {
    const {
        convertedAmount
    } = props;

    const tokenAddress = '0x0F1cBEd8EFa0E012AdbCCB1638D0aB0147D5Ac00';
    const tokenSymbol = 'HELLO';
    const tokenDecimals = 18;
    const tokenImage = 'https://github.com/Hello1Official/assets/blob/main/internal/assets/images/hello-coin-logo-32x.png?raw=true';

    const addToken = async () => {
        if (typeof window.ethereum !== 'undefined') {

            try {
                const wasAdded = await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: tokenAddress,
                            symbol: tokenSymbol,
                            decimals: tokenDecimals,
                            image: tokenImage
                        }
                    }
                })
                if (wasAdded) {
                    console.log('HELLO added correctly!')
                } else {
                    console.log('Something went wrong!')
                }
            } catch (err) {
                console.log(err)
            }

        }
    }
    const copyTokenAddress = () => {
        const copyText = '0x0F1cBEd8EFa0E012AdbCCB1638D0aB0147D5Ac00';

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText);

        // Alert the copied text
        alert('Copied: ' + copyText);
    }

    return (
        <>
            <div className="text-center space-y-4 my-6 md:my-12 mx-auto" style={{ maxWidth: '520px' }}>
                <h4 className="text-primary text-xl">Congratulations! You are officially a holder of<br /> <strong>{convertedAmount} HELLO Tokens</strong></h4>
            </div>
            <div className="flex flex-col justify-center items-center text-center space-y-4 my-6 md:my-12 mx-auto" style={{ maxWidth: '520px' }}>

                <span className="font-black">
                    Add token to your wallet:
                </span>

                <span className="flex">
                    <a className="cursor-pointer max-w-full text-ellipsis overflow-hidden ..." href="https://bscscan.com/address/0x0F1cBEd8EFa0E012AdbCCB1638D0aB0147D5Ac00" target={'_blank'}>
                        0x0F1cBEd8EFa0E012AdbCCB1638D0aB0147D5Ac00
                    </a>
                    <span>

                        <button
                            type="button"
                            className={`w-[25px] h-[25px] text-center justify-center flex items-center md:items-end rounded-full shadow-sm text-black bg-primary ml-2 enabled:hover:text-primary enabled:hover:bg-black enabled:hover:border-primary`}
                            onClick={() => copyTokenAddress()}
                        >
                            <svg className="w-[60%]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2z" /></svg>
                        </button>

                    </span>
                </span>

                <button
                    type="button"
                    className={`w-[200px] text-center uppercase justify-center inline-flex items-center md:items-end px-5 py-4 border border-black text-xs font-black rounded-full shadow-sm text-black bg-primary ml-2 enabled:hover:text-primary enabled:hover:bg-black enabled:hover:border-primary`}
                    onClick={() => addToken()}
                >
                    Add HELLO To Metamask
                </button>
            </div>

            <div className="mx-auto md:mt-8" style={{ maxWidth: '748px' }}>
                <p className="text-center text-sm">
                    Enter your email below to stay in the loop on all future announcements and updates.
                </p>
                <form action="#" className="mt-8 sm:mx-auto sm:max-w-lg sm:flex">
                    <div className="min-w-0 flex-1">
                        <label htmlFor="cta-email" className="sr-only">Add your email address</label>
                        <input id="cta-email" type="email" className="block w-full border border-transparent rounded-full px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-1 focus:ring-primary" placeholder="Add your email address" />
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-3">
                        <button type="submit" className="inline-flex justify-center w-full md:w-auto items-center text-center md:items-end px-8 py-4 border border-black text-xs font-black rounded-full shadow-sm text-black bg-primary md:ml-2 hover:text-primary hover:bg-black hover:border-primary">STAY IN THE LOOP</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Step4;