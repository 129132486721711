import { formatNumber } from "../utils/formatBalance";
import React from "react";

const ReadonlyCoinsAmount = ( props ) => {
    const {
        amount
    } = props;

    return (
        <span className="block w-full bg-white cursor-not-allowed border border-transparent rounded-full px-5 py-3 text-base text-left text-black shadow-sm">
            {formatNumber( amount, 6, 6 )}
        </span>
    )
}

export default ReadonlyCoinsAmount;