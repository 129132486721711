import React from 'react';
import ReactDOM from 'react-dom';
import { Web3ReactProvider } from "@web3-react/core";
import reportWebVitals from './reportWebVitals';
import { getLibrary } from "./utils/web3React";
import App from './App';
import './index.css';


ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Web3ReactProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
