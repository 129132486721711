import React, {useState, useEffect} from 'react';
import Cookies from 'universal-cookie';

import '../style/CookieConsent.css';

const cookies = new Cookies();

const CookieConsent = () => {
    const [tracker, setTracker] = useState(false);
  
    const setAccept = () => {
        cookies.set('tracking', true);
        setTracker(true);
    }
  
    const setDeny = () => {
        cookies.set('nottracking', true);
        setTracker(true);
    }
  
    useEffect(() => {
        if (cookies.get('tracking')) {
            setTracker(true);
        }
    }, [])

    return (
        <>
            {/* <GTM /> */}
            <div id="cookie" className={`cookie_banner ${!tracker ? 'show' : ''}`}>
                <div className='flex bg-primary text-black text-sm p-2'>
                    <div className='max-w-screen-l mx-auto flex items-center'>
                        <div>HELLO is using cookies to improve the user experience on our website! Would you like to help us?</div>

                        <div className='ml-4'>
                            <button type="button" onClick={setAccept} className="inline-flex items-center md:items-end px-4 py-2 border border-transparent text-xs font-black rounded-full shadow-sm text-primary bg-black hover:text-black hover:bg-transparent hover:border-black">ACCEPT</button>
                            <button type="button" onClick={setDeny} className="inline-flex items-center md:items-end px-4 py-2 border border-black text-xs font-black rounded-full shadow-sm text-black bg-transparent ml-2 hover:text-primary hover:bg-black hover:border-transparent">DENY</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CookieConsent;