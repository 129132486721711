import { useCallback } from 'react';
import { getDogedashTokenContract } from '../utils/getContract';
import { useWeb3React } from "@web3-react/core";
import { getSwapContractAddress } from "../utils/address";
import { toWei } from "../utils/formatBalance";

const useApprove = () => {
    const {library} = useWeb3React();
    const dogedashTokenContract = getDogedashTokenContract( library );
    const spenderAddress = getSwapContractAddress();

    const handleApprove = useCallback(
        async ( dogedashAmount ) => {
            const amountWei = toWei( dogedashAmount );
            console.log( `Approving amount ${amountWei}` );

            const approveTx = await dogedashTokenContract.approve( spenderAddress, amountWei );
            const approveReceipt = await approveTx.wait()
            return approveReceipt.status;
        }, [
            dogedashTokenContract,
            spenderAddress
        ]
    );

    return {onApprove: handleApprove};
};

export default useApprove;
