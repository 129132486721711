import React, { useState, useEffect } from "react";
import '../style/Navigation.css'
import logo from '../assets/img/logo.png'


const body = document.body;

const Navigation = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 30);
    });
  });

    return(
        <>
          <nav id="nav" className={`nav z-100 ${scroll ? "scrolled" : "bg-black"}`} style={{zIndex: 90}}>
            <div className="relative max-w-screen-2xl mx-auto pl-4 pr-4 h-full z-90">
              <div className="flex flex-col md:flex-row h-full justify-center items-start">
                <div className="branding">
                  <a href={`/`} >
                    <img src={logo} width={100} height={62} alt="logo" className="logo" />
                  </a>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="297.642" height="134.93" viewBox="0 0 297.642 134.93">
                      <g id="Main_Logo_White" data-name="Main Logo White" transform="translate(148.821 67.465)">
                        <path id="Path_976" data-name="Path 976" d="M128.141,81.236V40.085c0-9.2-2.781-16.593-8.05-21.4-4.112-3.755-9.64-5.822-16.181-6.12a39.114,39.114,0,0,0-5.177.114c-1.322.116-2.6.3-3.848.527C79.076,16.129,69.425,28.059,69.425,45.147v55.365c0,9.2,2.781,16.593,8.05,21.4,5.015,4.577,12.141,6.66,20.638,6.057l.721-.051c18.079-1.6,29.307-14.029,29.307-32.463v-7.33L107.29,89.957V95.6c0,2.72,0,10.994-8.558,11.753-2.467.216-4.294-.264-5.6-1.45-2.355-2.147-2.71-6.386-2.71-8.71V84.564ZM90.418,44.986c0-2.72,0-11,8.416-11.743l.244-.017a6.872,6.872,0,0,1,5.36,1.467c2.355,2.148,2.71,6.376,2.71,8.71V64.436L90.418,65.918ZM0,134.93V19.946l21-1.491V72.023l17.014-1.492V17.247l20.993-1.49V130.741l-20.993,1.49V90.814L21,92.306v41.133ZM180.3,101.9v20.152l-42.95,3.049V10.118l21-1.491V103.46Zm53.365-3.788v20.151l-42.95,3.049V6.33l21-1.491V99.672ZM289.977,6.594C284.67,1.641,277.2-.537,267.778.112,258,.788,250.07,4.388,244.842,10.523a29.816,29.816,0,0,0-6.827,19.4V92.1c0,7.839,2.722,14.7,7.665,19.308,4.7,4.383,11.085,6.595,19.022,6.595q1.549,0,3.177-.112c9.776-.676,17.707-4.276,22.936-10.411a29.816,29.816,0,0,0,6.827-19.4V25.9C297.642,18.064,294.92,11.207,289.977,6.594ZM277.3,29.218V87.371c0,9.264-7.289,10.489-9.522,10.643-2.859.2-5.127-.465-6.738-1.969-2.334-2.178-2.682-5.485-2.682-7.262V30.63c0-9.264,7.288-10.488,9.522-10.643q.493-.034.964-.034a8.152,8.152,0,0,1,5.774,2C276.95,24.133,277.3,27.44,277.3,29.218Z" transform="translate(-148.821 -67.465)" fill="#fff"/>
                      </g>
                    </svg>
                  </a> */}
                </div>
              </div>

            </div>
          </nav>
        </>
    )
}

export default Navigation;