import { formatEther, parseEther, parseUnits } from "@ethersproject/units";

/**
 * Convert a WEI amount (e.g. 15000000000000000000 wei) to a readable format (e.g. 15 eth)
 * @param amount: ethers.BigNumber
 * @param displayDecimals: number
 * @return number
 */
export function fromWei( amount, displayDecimals = 6 )
{
    const amountAsString = amount.toString();
    const weiNumber = parseUnits( amountAsString, "wei" );
    const etherNumber = formatEther( weiNumber );
    const etherTruncated = ( +etherNumber ).toFixed( displayDecimals );
    return parseFloat( etherTruncated );
}

/**
 * Convert a readable amount (e.g. 15 eth) to a WEI amount (e.g. 15000000000000000000 wei)
 * @param amount: ethers.BigNumber
 * @return string
 */
export function toWei( amount )
{
    return parseEther( amount ).toString();
}

/**
 * Format a number with thousands separator.
 * @param number
 * @param minPrecision
 * @param maxPrecision
 * @return {string}
 */
export const formatNumber = ( number, minPrecision = 2, maxPrecision = 2 ) => {
    const options = {
        minimumFractionDigits: minPrecision,
        maximumFractionDigits: maxPrecision,
    }
    return number.toLocaleString( undefined, options )
}