import React, { useEffect, useState } from "react";
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import TagManager from 'react-gtm-module';

import Home from './pages/Home';
import Swap from './pages/Swap';

import Navigation from './components/Navigation';
import CookieConsent from "./components/CookieConsent";
import Cookies from 'universal-cookie';
import BotFloatBanner from "./components/BotFloatBanner";


const tagManagerArgs = {
    gtmId: 'G-WBCL0QQLJJ'
}

const cookies = new Cookies();

const App = () => {
  const [hide, setHide] = useState(false);

  const Initialize = () => {
    TagManager.initialize(tagManagerArgs);
  }

  useEffect(() => {
      if (cookies.get('tracking', true)) {
        Initialize()
        setHide(true)
      } 
      
      if (cookies.get('nottracking')) {
        setHide(true)
        TagManager.initialize(false);
        cookies.remove('_gid')
        cookies.remove('_ga')
        cookies.remove('_gat_gtag_UA_226603277_1')
      } else {
        setHide(false)
        Initialize()
      }
  }, [hide]);

  return (
    <>
      {hide ? (
        <CookieConsent />
      ) : ''}
      <Router>
        <Navigation />
        <Routes>
          {/* <Route path={`/`} element={<Home />} /> */}

          <Route path={`/`} element={<Swap />} />

        </Routes>
        <BotFloatBanner />
    </Router></>
  );
}

export default App;
