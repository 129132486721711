import { ethers } from 'ethers'
import swapABI from '../config/abi/swapABI.json';
import ddTokenABI from '../config/abi/dogedashTokenABI.json';
import h1TokenABI from '../config/abi/helloOneTokenABI.json';
import { getDogedashTokenAddress, getHelloOneTokenAddress, getSwapContractAddress } from "./address";

const getContract = ( abi, address, library ) => {
    if ( !library ) {
        throw new Error( 'Wallet not connected.' );
    }

    return new ethers.Contract( address, abi, library.getSigner() );
}

export const getSwapContract = ( library ) => getContract( swapABI, getSwapContractAddress(), library );
export const getDogedashTokenContract = ( library ) => getContract( ddTokenABI, getDogedashTokenAddress(), library );
export const getH1TokenContract = ( library ) => getContract( h1TokenABI, getHelloOneTokenAddress(), library );